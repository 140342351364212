import React from 'react'
import { useSelector } from 'react-redux';
import FlipBook from '../../components/FlipBook/FlipBook'
import CustomButton1 from '../../components/CustomButton/CustomButton1'

export default function BookCollect() {

	const bookData = useSelector((state) => state.bookData.bookData);
	console.log(bookData);

	const handleDownloadPdf = () => {
		console.log("handleDownloadPdf");
	}


	return (
		<div className='w-full pt-20'>
			{
				bookData ?
					<>
						<FlipBook pdf={bookData.pdfs.pdf1} />
						<div className=' w-full flex flex-row justify-center items-center'>
							<div className='w-9/12 mt-20'>
								<h1>Book Name : {bookData.name}</h1>
								<h2>Author Name : {bookData.authorName}</h2>
								<p>Description : {bookData.description}</p>
								<div>
									{/* <h3>Book Status : {bookData.bookLocation ? "Available" : "Not Available"}</h3> */}
									{/* <h3>Book Order Status : {bookData.orderedList.length} person Ordered</h3> */}
								</div>
							</div>
							<div className='w-3/12'>
								{/* <CustomButton1 title={"Download Pdf"} fun={handleDownloadPdf} padding={"12px 35px"} /> */}
							</div>
						</div>
					</>
					:
					<></>
			}
		</div>
	)
}
