import React, { useState, useEffect } from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/slice/AuthSlice';
import { addProfileData } from '../../store/slice/ProfileSlice';

import location from '../../assets/Libraryicon/location.png'
import phone from '../../assets/Libraryicon/phone.png'
import email from '../../assets/Libraryicon/mail.png'

export default function Profile() {
    const dispatch = useDispatch();

    const profileData = useSelector((state) => state.profile.profile);

    const [viewImage, setViewImage] = useState(false);
    const [image, setImage] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confromNewPassword, setConfromNewPassword] = useState('');

    useEffect(() => {
        handleGetProfile();
    }, []);

    const handleGetProfile = async () => {
        const token = Cookies.get('auth_token');
        try {
            const result = await axios.post(`https://api.dakshineswarshayaklibrary.org/User/getProfile`, null, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (result.data.status === true) {
                dispatch(addProfileData(result.data.existingUser));
            } else {
                alert(result.data.error);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const convertUserIMG = (e) => {
        setImage(e.target.files[0]);
        var fileReader = new FileReader();
        fileReader.readAsDataURL(e.target.files[0]);
        fileReader.onload = () => {
            setViewImage(fileReader.result);
        };
    };

    const handleUpdateProfileImage = async () => {
        const token = Cookies.get('auth_token');

        if (image) {
            const formData = new FormData();
            formData.append('image', image);

            try {
                const result = await axios.post(`https://api.dakshineswarshayaklibrary.org/User/updateProfileImage`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`
                    }
                });
                if (result.data.status === true) {
                    alert("Image Update Successful!!!");
                    handleGetProfile();
                }
                else {
                    alert(result.data.error)
                }
            } catch (error) {
                alert(error)
            }
        } else {
            alert("Please Click the image for taking new image")
        }
    };

    const handleUpdatePassword = async () => {
        if (newPassword !== confromNewPassword) {
            return alert("newPassword and confromNewPassword are not same");
        }
        const token = Cookies.get('auth_token');

        const formData = new FormData();
        formData.append('currentPassword', currentPassword);
        formData.append('newPassword', newPassword);

        try {
            const result = await axios.post(`https://api.dakshineswarshayaklibrary.org/User/updatePassword`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });
            if (result.data.status === true) {
                alert("Password Update Successful!!!");
                handleLogout();
            }
            else {
                alert(result.data.error)
            }
        } catch (error) {
            alert(error)
        }
    };

    const handleLogout = () => {
        Cookies.remove('auth_token');
        dispatch(logout());
    };


    
    return (
        <div className='w-full h-[calc(100vh-114px)]'>
            {
                profileData ? (
                    <div className='w-full h-full flex justify-center items-center'>
                        <div className='w-[60%] h-full flex justify-center items-center relative'>
                            <div className='h-[800px] w-[600px] rounded-3xl border border-[#8343FF] flex justify-center items-center'>
                                <div className='h-[750px] w-[550px] rounded-3xl flex flex-col justify-center items-center bg-[#E1D1FF]'>
                                    <div className='flex flex-col justify-center items-center'>
                                        <div className='h-[130px] w-[130px] relative'>
                                            <input
                                                type="file"
                                                onChange={convertUserIMG}
                                                className='h-full w-full absolute rounded-full opacity-0'
                                            />
                                            <img
                                                src={viewImage ? viewImage : `https://api.dakshineswarshayaklibrary.org/uploads/${profileData.profileIMG}`}
                                                alt="Uploaded"
                                                className='h-full w-full rounded-full object-cover'
                                            />
                                        </div>
                                        <button
                                            className='text-xl font-bold text-[#8343FF] mt-2'
                                            onClick={handleUpdateProfileImage}
                                        >
                                            Update Photo
                                        </button>
                                        <h1 className='text-3xl font-bold text-[#8343FF]'>{profileData.name}</h1>
                                    </div>
                                    <div className='my-5 px-2 pb-5'>
                                        <h1 className='text-2xl font-bold text-[#8343FF]'>
                                            Address: <span className='text-lg font-bold ml-2'>{profileData.address}</span>
                                        </h1>
                                        <h1 className='text-2xl font-bold text-[#8343FF]'>
                                            Number: <span className='text-lg font-bold ml-2'>{profileData.mobileNumber}</span>
                                        </h1>
                                        <h1 className='text-2xl font-bold text-[#8343FF]'>
                                            Email: <span className='text-lg font-bold ml-2'>{profileData.email}</span>
                                        </h1>
                                    </div>
                                    <div className='w-full flex flex-col justify-center items-center gap-4'>
                                        <span className='h-[2px] bg-[#8343FF] w-[60%] mb-4'></span>
                                        <h2 className='text-xl font-bold text-[#8343FF]'>Change Password</h2>
                                        <input
                                            type='password'
                                            placeholder='Enter Current Password'
                                            onChange={(e) => setCurrentPassword(e.target.value)}
                                            className='w-[80%] h-[40px] px-2 rounded-md outline-none text-center border-2 border-[#8343FF]'
                                        />
                                        <input
                                            type='password'
                                            placeholder='Enter New Password'
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            className='w-[80%] h-[40px] px-2 rounded-md outline-none text-center border-2 border-[#8343FF]'
                                        />
                                        <input
                                            type='password'
                                            placeholder='Confirm Password'
                                            onChange={(e) => setConfromNewPassword(e.target.value)}
                                            className='w-[80%] h-[40px] px-2 rounded-md outline-none text-center border-2 border-[#8343FF]'
                                        />
                                        <button
                                            className='w-[80%] h-[40px] text-white text-center rounded-md bg-[#8343FF]'
                                            onClick={handleUpdatePassword}
                                        >
                                            Update Password
                                        </button>
                                        <button
                                            className='text-xl font-bold text-[#8343FF]'
                                            onClick={handleLogout}
                                        >
                                            LOG OUT
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <span className='h-[80%] w-[3px] bg-[#8343FF] absolute right-0'></span>
                        </div>
                        <div className='w-[40%] h-full flex justify-center items-center'>
                            <div className='flex flex-col justify-start items-center gap-16 h-[600px]'>
                                <div className='flex flex-col justify-center items-center'>
                                    {/* <h1>Logo</h1> */}
                                    <h1 className='text-3xl text-[#8343FF] font-bold'> DAKSHINESWAR SHAYAK LIBRARY</h1>
                                </div>
                                <div className='w-[70%]'>
                                    <div className='flex items-center gap-5 mb-5'>
                                        <img src={location} className='h-[40px]' alt="Location" />
                                        <h2 className='text-xl font-bold text-[#8343FF]'>11, Nepal Chandra Chatterjee Street, Ariadaha, Kolkata -700057</h2>
                                    </div>
                                    <div className='flex items-center gap-5 mb-5'>
                                        <img src={phone} className='h-[40px]' alt="Phone" />
                                        <h2 className='text-xl font-bold text-[#8343FF]'>8961230419</h2>
                                    </div>
                                    <div className='flex items-center gap-5'>
                                        <img src={email} className='h-[40px]' alt="Email" />
                                        <h2 className='text-xl font-bold text-[#8343FF]'>toskdenre@gmail.com</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <p>Loading...</p>
                )
            }
        </div>

    );
}
