import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function MonthlySubscriptionFees() {
    const [notPaymentMonth, setNotPaymentMonth] = useState([]);
    const [email, setEmail] = useState("");
    const [inputs, setInputs] = useState([{ date: '', amount: '' }]);


    useEffect(() => {
        if (email) {
            const timer = setTimeout(() => {
                fetchMonthsData();
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [email]);

    const fetchMonthsData = async () => {
        try {
            const feeResponse = await axios.get(`https://api.dakshineswarshayaklibrary.org/Admin/checkPendingSubscription/${email}`);
            setNotPaymentMonth(feeResponse.data.missingMonths);
        } catch (error) {
            console.error("There was an error fetching the subscription data!", error);
        }
    };

    const handleSubmit = async () => {
        try {
            await axios.post(`https://api.dakshineswarshayaklibrary.org/Admin/updateSubscriptionFees/${email}`, { inputs });
            alert('Subscription fee updated successfully');
        } catch (error) {
            console.error('There was an error updating the subscription fee!', error);
        }
    };

    const addField = () => {
        setInputs([...inputs, { date: '', amount: '' }]);
    };

    const handleInputChange = (index, event) => {
        const values = [...inputs];
        values[index][event.target.name] = event.target.value;
        setInputs(values);
    };

    return (
        <div>
            <div>
                <input
                    type='email'
                    placeholder='Enter your email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div>
                {
                    notPaymentMonth.map((month) => {
                        return (
                            <p>{month}</p>
                        );
                    })
                }
            </div>
            <button onClick={addField}>Add</button>
            <div>
                {inputs.map((input, index) => (
                    <div key={index}>
                        <input
                            type='date'
                            name='date'
                            value={input.date}
                            onChange={event => handleInputChange(index, event)}
                        />
                        <input
                            type='number'
                            name='amount'
                            placeholder='Enter amount'
                            value={input.amount}
                            onChange={event => handleInputChange(index, event)}
                        />
                    </div>
                ))}
            </div>
            <button onClick={handleSubmit}>Submit</button>
        </div>
    );
}
