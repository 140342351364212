import React, { useRef, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactPrint from 'react-to-print';
import { Link } from 'react-router-dom';

function Register() {
    const ref = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [parentsName, setParentsName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [presentAddress, setPresentAddress] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [parentsOccupation, setParentsOccupation] = useState('');
    const [bloodGroup, setBloodGroup] = useState('');
    const [instituteName, setInstituteName] = useState('');
    const [courseName, setCourseName] = useState('');
    const [subjectName, setSubjectName] = useState('');
    const [hobbyName, setHobbyName] = useState('');
    const [remarksName, setRemarksName] = useState('');
    const [checked, setChecked] = useState();


const handleCheckboxChange = (e) => {
	setChecked(e.target.checked);
};



    const handelRegister = async () => {
		if(checked) {
			if (!name || !parentsName || !mobileNumber || !address || !presentAddress || !email || 
				!password || !parentsOccupation || !bloodGroup || !instituteName 
				|| !courseName || !subjectName || !hobbyName || !remarksName) {
				toast.error("Please fill all required fields");
				return;
			}
	
			const formData = new FormData();
			formData.append("name", name);
			formData.append("parentsName", parentsName);
			formData.append("mobileNumber", mobileNumber);
			formData.append("address", address);
			formData.append("presentAddress", presentAddress);
			formData.append("email", email);
			formData.append("password", password);
			formData.append("parentsOccupation", parentsOccupation);
			formData.append("bloodGroup", bloodGroup);
			formData.append("instituteName", instituteName);
			formData.append("courseName", courseName);
			formData.append("subjectName", subjectName);
			formData.append("hobbyName", hobbyName);
			formData.append("remarksName", remarksName);
	
			try {
				const result = await axios.post('https://api.dakshineswarshayaklibrary.org/User/register', formData, {
					headers: {
						'Content-Type': 'application/json'
					}
				});
				if (result.data.status === true) {
					toast.success('Registration successful!');
					navigate('/ApproveByOTP');
					
				} else {
					toast.error(result.data.message);
				}
			} catch (error) {
				toast.error(error.response?.data?.message || error.message);
			}
		} else {
			toast.error("Please Check terms and conditions");
		}
    };

    return (
        <>
            <div
                className="application-form"
                style={{
                    fontFamily: "Arial, sans-serif",
                    padding: "20px",
                    maxWidth: "800px",
					paddingTop:'114px',
                    margin: "auto",
                }}
                ref={ref}
            >
                <h2 style={{ textAlign: "center" }}> DAKSHINESWAR SHAYAK LIBRARY</h2>
                <p style={{ textAlign: "center" }}>
                11, Nepal Chandra Chatterjee Street,<br></br> Ariadaha, Kolkata -700057
                </p>
                <p style={{ textAlign: "center" }}>Established:1996</p>
                <p style={{ textAlign: "center" }}>Registration No: SO087920</p>
                <h3
                    style={{
                        textAlign: "center",
                        textDecoration: "underline",
                        fontSize: "20px",
                    }}
                >
                    Application Form
                </h3>

                <div style={{ marginTop: "50px" }}>
                    <label>NAME:</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        style={{
                            marginLeft: "15px",
                            width: "87%",
                            marginBottom: "10px",
                            border: "1px solid black",
                        }}
                    />

                    <label>GUARDIAN'S NAME:</label>
                    <input
                        type="text"
                        value={parentsName}
                        onChange={(e) => setParentsName(e.target.value)}
                        style={{
                            marginLeft: "15px",
                            width: "52%",
                            marginBottom: "10px",
                            border: "1px solid black",
                        }}
                    />

                    <label>GUARDIAN'S OCCUPATION:</label>
                    <input
                        type="text"
                        value={parentsOccupation}
                        onChange={(e) => setParentsOccupation(e.target.value)}
                        style={{
                            marginLeft: "15px",
                            width: "43%",
                            marginBottom: "10px",
                            border: "1px solid black",
                        }}
                    />

                    <label>
                        PRESENT ADDRESS.:
                    </label>
                    <input
                        type="text"
                        value={presentAddress}
                        onChange={(e) => setPresentAddress(e.target.value)}
                        style={{
                            marginLeft: "15px",
                            width: "47%",
                            marginBottom: "10px",
                            border: "1px solid black",
                        }}
                    />
                    <label>
                        TELEPHONE NO.:
                    </label>
                    <input
                        type="number"
                        value={mobileNumber}
                        onChange={(e) => setMobileNumber(e.target.value)}
                        style={{
                            marginLeft: "15px",
                            width: "80%",
                            marginBottom: "10px",
                            border: "1px solid black",
                        }}
                    />

                    <label>PERMANENT ADDRESS:</label>
                    <input
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        style={{
                            marginLeft: "15px",
                            width: "62%",
                            marginBottom: "10px",
                            border: "1px solid black",
                        }}
                    />
                    <label>BLOOD GROUP DropDown hobe:</label>
                    <input
                        type="text"
                        value={bloodGroup}
                        onChange={(e) => setBloodGroup(e.target.value)}
                        style={{
                            marginLeft: "15px",
                            width: "80%",
                            marginBottom: "10px",
                            border: "1px solid black",
                        }}
                    />

                    <label>
                       NAME OF THE PRESENT INSTITUTION:
                    </label>
                    <input
                        type="text"
                        value={instituteName}
                        onChange={(e) => setInstituteName(e.target.value)}
                        style={{
                            marginLeft: "15px",
                            width: "38%",
                            marginBottom: "10px",
                            border: "1px solid black",
                        }}
                    />

                    <label>COURSE:</label>
                    <input
                        type="text"
                        value={courseName}
                        onChange={(e) => setCourseName(e.target.value)}
                        style={{
                            marginLeft: "15px",
                            width: "80%",
                            marginBottom: "10px",
                            border: "1px solid black",
                        }}
                    />

                    <label>SUBJECT:</label>
                    <input
                        type="text"
                        value={subjectName}
                        onChange={(e) => setSubjectName(e.target.value)}
                        placeholder='Ex. Math, English, Physics Chemistry'
                        style={{
                            marginLeft: "15px",
                            width: "82%",
                            marginBottom: "10px",
                            border: "1px solid black",
                        }}
                    />

                    <h4>RECOMMENDED BY:</h4>
                    <table
                        style={{
                            width: "100%",
                            textAlign: "center",
                            marginTop: '10px',
                            marginBottom: '50px',
                            borderCollapse: "collapse",
                            border: '1px solid black',
                        }}
                    >
                        <thead style={{ border: "1px solid black" }}>
                            <tr style={{ border: "1px solid black" }}>
                                <th style={{ border: "1px solid black", textAlign: "center" }}>
                                    NAME
                                </th>
                                <th style={{ border: "1px solid black", textAlign: "center" }}>
                                   ADDRESS
                                </th>
                                <th style={{ border: "1px solid black", textAlign: "center" }}>
                                    DESIGNATION
                                </th>
                                <th style={{ border: "1px solid black", textAlign: "center" }}>
                                   SIGNATURE
                                </th>
                            </tr>
                        </thead>
                        <tbody style={{ border: "1px solid black" }}>
                            <tr style={{ border: "1px solid black" }}>
                                <td style={{ border: "1px solid black" }}>
                                    <input type="text" style={{ width: "100%" }} />
                                </td>
                                <td style={{ border: "1px solid black" }}>
                                    <input type="text" style={{ width: "100%" }} />
                                </td>
                                <td style={{ border: "1px solid black" }}>
                                    <input type="text" style={{ width: "100%" }} />
                                </td>
                                <td style={{ border: "1px solid black" }}>
                                    <input type="text" style={{ width: "100%" }} />
                                </td>
                            </tr>
                            <tr style={{ border: "1px solid black" }}>
                                <td style={{ border: "1px solid black" }}>
                                    <input type="text" style={{ width: "100%" }} />
                                </td>
                                <td style={{ border: "1px solid black" }}>
                                    <input type="text" style={{ width: "100%" }} />
                                </td>
                                <td style={{ border: "1px solid black" }}>
                                    <input type="text" style={{ width: "100%" }} />
                                </td>
                                <td style={{ border: "1px solid black" }}>
                                    <input type="text" style={{ width: "100%" }} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <label>INTEREST/HOBBY:</label>
                    <input
                        type="text"
                        value={hobbyName}
                        onChange={(e) => setHobbyName(e.target.value)}
                        style={{
                            marginLeft: "15px",
                            width: "73%",
                            marginBottom: "10px",
                            border: "1px solid black",
                        }}
                    />

                    <label>REMARKS:</label>
                    <input
                        type="text"
                        value={remarksName}
                        onChange={(e) => setRemarksName(e.target.value)}
                        style={{
                            marginLeft: "15px",
                            width: "81%",
                            marginBottom: "10px",
                            border: "1px solid black",
                        }}
                    />

                    <p>
                        I hereby declare that the above
                        information is true and correct to the best of my knowledge. In case
                        of any discrepancy, I will be responsible for the same.
                    </p>

                    <div style={{
                        width: "100%",
                        display: 'flex',
                    }}>
                        <div style={{
                            width: "50%",
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <label>DATE:</label>
                            <div style={{
                                width: "50%",
                                height:"25px",
                                marginBottom: "10px",
                                border: "1px solid black",
                            }}></div>
                            
                            <label>PLACE:</label>
                            <input
                                type="text"
                                style={{
                                    width: "60%",
                                    marginBottom: "10px",
                                    border: "1px solid black",
                                }}
                            />
                        </div>
                        <div style={{
                            width: "50%",
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                            <label>Signature of the Applicant:</label>
                            <input
                                type="text"
                                style={{
                                    width: "70%",
                                    marginBottom: "10px",
                                    border: "1px solid black",
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                fontFamily: "Arial, sans-serif",
                padding: "20px",
                maxWidth: "800px",
                margin: "auto",
            }}>
                <label>
                    Email.:
                </label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{
                        marginLeft: "15px",
                        width: "90%",
                        marginBottom: "10px",
                        border: "1px solid black",
                    }}
                />

                <label>Password:</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{
                        marginLeft: "15px",
                        width: "86%",
                        marginBottom: "10px",
                        border: "1px solid black",
                    }}
                />
                 <div>
            <input
                type="checkbox"
                checked={checked}
                onChange={handleCheckboxChange}
            />
            <label style={{marginLeft:'10px'}}>I Accept Terms and Conditions</label>
        </div>
                <Link to='/Register'>Already have an account?</Link>
            </div>
            <div style={{
                fontFamily: "Arial, sans-serif",
                padding: "20px",
                maxWidth: "800px",
                margin: "auto",
            }}>
               <button onClick={handelRegister}>Submit</button>
            </div>
			<ReactPrint
                trigger={() => <button>Print</button>}
                content={() => ref.current}
            />
            <ToastContainer />
        </>
    );
}

export default Register;












































// import React, { useRef, useState } from 'react';
// import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import ReactPrint from 'react-to-print';
// import { Link } from 'react-router-dom';

// function Login() {
//     const ref = useRef();
//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     const [name, setName] = useState('');
//     const [parentsName, setParentsName] = useState('');
//     const [mobileNumber, setMobileNumber] = useState('');
//     const [address, setAddress] = useState('');
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [parentsOccupation, setParentsOccupation] = useState('');
//     const [bloodGroup, setBloodGroup] = useState('');
//     const [instituteName, setInstituteName] = useState('');
//     const [courseName, setCourseName] = useState('');
//     const [subjectName, setSubjectName] = useState('');
//     const [hobbyName, setHobbyName] = useState('');
//     const [remarksName, setRemarksName] = useState('');

//     const handelRegister = async (event) => {
//         event.preventDefault();
//         if (!name || !parentsName || !mobileNumber || !address || !email || 
//             !password || !parentsOccupation || !bloodGroup || !instituteName 
//             || !courseName || !subjectName || !hobbyName || !remarksName) {
//             toast.error("Please fill all required fields");
//             return;
//         }

//         const formData = new FormData();
//         formData.append("name", name);
//         formData.append("parentsName", parentsName);
//         formData.append("mobileNumber", mobileNumber);
//         formData.append("address", address);
//         formData.append("email", email);
//         formData.append("password", password);
//         formData.append("parentsOccupation", parentsOccupation);
//         formData.append("bloodGroup", bloodGroup);
//         formData.append("instituteName", instituteName);
//         formData.append("courseName", courseName);
//         formData.append("subjectName", subjectName);
//         formData.append("hobbyName", hobbyName);
//         formData.append("remarksName", remarksName);

//         try {
//             const result = await axios.post('https://api.dakshineswarshayaklibrary.org/User/register', formData, {
//                 headers: {
//                     'Content-Type': 'application/json'
//                 }
//             });
//             if (result.data.status === true) {
//                 navigate('/ApproveByOTP');
//             } else {
//                 toast.error(result.data.message);
//             }
//         } catch (error) {
//             toast.error(error.response?.data?.message || error.message);
//         }
//     };

//     return (
//         <>
//             <div
//                 className="application-form"
//                 style={{
//                     fontFamily: "Arial, sans-serif",
//                     padding: "20px",
//                     maxWidth: "800px",
//                     margin: "auto",
//                 }}
//                 ref={ref}
//             >
//                 <h2 style={{ textAlign: "center" }}>দক্ষিণেশ্বর শামুক লাইব্রেরি</h2>
//                 <p style={{ textAlign: "center" }}>
//                     ডাঃ রাধা বালান গাঙ্গুলী স্মৃতি ভবন
//                     <br />
//                     ১১, লেডি চন্দ্র স্ট্রীট, কলকাতা-৭০০ ০০৭
//                 </p>
//                 <p style={{ textAlign: "center" }}>প্রবেশ নং: এস/৮৬১০</p>
//                 <h3
//                     style={{
//                         textAlign: "center",
//                         textDecoration: "underline",
//                         fontSize: "20px",
//                     }}
//                 >
//                     আবেদন পত্র
//                 </h3>

//                 <form style={{ marginTop: "50px" }} onSubmit={handelRegister}>
//                     <label>নাম/NAME:</label>
//                     <input
//                         type="text"
//                         value={name}
//                         onChange={(e) => setName(e.target.value)}
//                         style={{
//                             marginLeft: "15px",
//                             width: "87%",
//                             marginBottom: "10px",
//                             border: "1px solid black",
//                         }}
//                     />

//                     <label>অভিভাবক/অভিভাবিকার নাম/GUARDIAN'S NAME:</label>
//                     <input
//                         type="text"
//                         value={parentsName}
//                         onChange={(e) => setParentsName(e.target.value)}
//                         style={{
//                             marginLeft: "15px",
//                             width: "52%",
//                             marginBottom: "10px",
//                             border: "1px solid black",
//                         }}
//                     />

//                     <label>অভিভাবক/অভিভাবিকার পেশা/GUARDIAN'S OCCUPATION:</label>
//                     <input
//                         type="text"
//                         value={parentsOccupation}
//                         onChange={(e) => setParentsOccupation(e.target.value)}
//                         style={{
//                             marginLeft: "15px",
//                             width: "43%",
//                             marginBottom: "10px",
//                             border: "1px solid black",
//                         }}
//                     />

//                     <label>
//                         বর্তমান ঠিকানা ও টেলিফোন নম্বর/PRESENT ADDRESS.:
//                     </label>
//                     <input
//                         type="text"
//                         value={address}
//                         onChange={(e) => setAddress(e.target.value)}
//                         style={{
//                             marginLeft: "15px",
//                             width: "47%",
//                             marginBottom: "10px",
//                             border: "1px solid black",
//                         }}
//                     />
//                     <label>
//                         TELEPHONE NO.:
//                     </label>
//                     <input
//                         type="text"
//                         value={mobileNumber}
//                         onChange={(e) => setMobileNumber(e.target.value)}
//                         style={{
//                             marginLeft: "15px",
//                             width: "80%",
//                             marginBottom: "10px",
//                             border: "1px solid black",
//                         }}
//                     />

//                     <label>স্থায়ী ঠিকানা/PERMANENT ADDRESS:</label>
//                     <input
//                         type="text"
//                         value={address}
//                         onChange={(e) => setAddress(e.target.value)}
//                         style={{
//                             marginLeft: "15px",
//                             width: "62%",
//                             marginBottom: "10px",
//                             border: "1px solid black",
//                         }}
//                     />
//                     <label>BLOOD GROUP:</label>
//                     <input
//                         type="text"
//                         value={bloodGroup}
//                         onChange={(e) => setBloodGroup(e.target.value)}
//                         style={{
//                             marginLeft: "15px",
//                             width: "80%",
//                             marginBottom: "10px",
//                             border: "1px solid black",
//                         }}
//                     />

//                     <label>
//                         বর্তমান প্রতিষ্ঠানের নাম/NAME OF THE PRESENT INSTITUTION:
//                     </label>
//                     <input
//                         type="text"
//                         value={instituteName}
//                         onChange={(e) => setInstituteName(e.target.value)}
//                         style={{
//                             marginLeft: "15px",
//                             width: "38%",
//                             marginBottom: "10px",
//                             border: "1px solid black",
//                         }}
//                     />

//                     <label>পাঠ্যক্রম/COURSE:</label>
//                     <input
//                         type="text"
//                         value={courseName}
//                         onChange={(e) => setCourseName(e.target.value)}
//                         style={{
//                             marginLeft: "15px",
//                             width: "80%",
//                             marginBottom: "10px",
//                             border: "1px solid black",
//                         }}
//                     />

//                     <label>বিষয়/SUBJECT:</label>
//                     <input
//                         type="text"
//                         value={subjectName}
//                         onChange={(e) => setSubjectName(e.target.value)}
//                         style={{
//                             marginLeft: "15px",
//                             width: "82%",
//                             marginBottom: "10px",
//                             border: "1px solid black",
//                         }}
//                     />

//                     <h4>প্রস্তাবিত/RECOMMENDED BY:</h4>
//                     <table
//                         style={{
//                             width: "100%",
//                             textAlign: "center",
//                             marginTop: '10px',
//                             marginBottom: '50px',
//                             borderCollapse: "collapse",
//                             border: '1px solid black',
//                         }}
//                     >
//                         <thead style={{ border: "1px solid black" }}>
//                             <tr style={{ border: "1px solid black" }}>
//                                 <th style={{ border: "1px solid black", textAlign: "center" }}>
//                                     নাম/NAME
//                                 </th>
//                                 <th style={{ border: "1px solid black", textAlign: "center" }}>
//                                     ঠিকানা/ADDRESS
//                                 </th>
//                                 <th style={{ border: "1px solid black", textAlign: "center" }}>
//                                     পদ/DESIGNATION
//                                 </th>
//                                 <th style={{ border: "1px solid black", textAlign: "center" }}>
//                                     স্বাক্ষর/SIGNATURE
//                                 </th>
//                             </tr>
//                         </thead>
//                         <tbody style={{ border: "1px solid black" }}>
//                             <tr style={{ border: "1px solid black" }}>
//                                 <td style={{ border: "1px solid black" }}>
//                                     <input type="text" style={{ width: "100%" }} />
//                                 </td>
//                                 <td style={{ border: "1px solid black" }}>
//                                     <input type="text" style={{ width: "100%" }} />
//                                 </td>
//                                 <td style={{ border: "1px solid black" }}>
//                                     <input type="text" style={{ width: "100%" }} />
//                                 </td>
//                                 <td style={{ border: "1px solid black" }}>
//                                     <input type="text" style={{ width: "100%" }} />
//                                 </td>
//                             </tr>
//                         </tbody>
//                     </table>

//                     <label>আগ্রহ/INTEREST/HOBBY:</label>
//                     <input
//                         type="text"
//                         value={hobbyName}
//                         onChange={(e) => setHobbyName(e.target.value)}
//                         style={{
//                             marginLeft: "15px",
//                             width: "73%",
//                             marginBottom: "10px",
//                             border: "1px solid black",
//                         }}
//                     />

//                     <label>মন্তব্য/REMARKS:</label>
//                     <input
//                         type="text"
//                         value={remarksName}
//                         onChange={(e) => setRemarksName(e.target.value)}
//                         style={{
//                             marginLeft: "15px",
//                             width: "81%",
//                             marginBottom: "10px",
//                             border: "1px solid black",
//                         }}
//                     />

//                     <p>
//                         উপরের তথ্যগুলি আমার জানা মতে সত্য। এই সম্পর্কে কোনো তথ্য ভুল পাওয়া
//                         গেলে আমি এর জন্য দায়ী থাকবো। I hereby declare that the above
//                         information is true and correct to the best of my knowledge. In case
//                         of any discrepancy, I will be responsible for the same.
//                     </p>

//                     <div style={{
//                         width: "100%",
//                         display: 'flex',
//                     }}>
//                         <div style={{
//                             width: "50%",
//                             display: 'flex',
//                             flexDirection: 'column'
//                         }}>
//                             <label>তারিখ/DATE:</label>
//                             <div style={{
//                                 width: "50%",
//                                 height:"25px",
//                                 marginBottom: "10px",
//                                 border: "1px solid black",
//                             }}></div>
                            
//                             <label>স্থান/PLACE:</label>
//                             <input
//                                 type="text"
//                                 style={{
//                                     width: "60%",
//                                     marginBottom: "10px",
//                                     border: "1px solid black",
//                                 }}
//                             />
//                         </div>
//                         <div style={{
//                             width: "50%",
//                             display: 'flex',
//                             flexDirection: 'column',
//                             alignItems: 'center',
//                             justifyContent: 'flex-end'
//                         }}>
//                             <label>স্বাক্ষর/Signature of the Applicant:</label>
//                             <input
//                                 type="text"
//                                 style={{
//                                     width: "70%",
//                                     marginBottom: "10px",
//                                     border: "1px solid black",
//                                 }}
//                             />
//                         </div>
//                     </div>
//                 </form>
//             </div>
//             <div style={{
//                 fontFamily: "Arial, sans-serif",
//                 padding: "20px",
//                 maxWidth: "800px",
//                 margin: "auto",
//             }}>
//                 <label>
//                     Email.:
//                 </label>
//                 <input
//                     type="email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     style={{
//                         marginLeft: "15px",
//                         width: "90%",
//                         marginBottom: "10px",
//                         border: "1px solid black",
//                     }}
//                 />

//                 <label>Password:</label>
//                 <input
//                     type="password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     style={{
//                         marginLeft: "15px",
//                         width: "86%",
//                         marginBottom: "10px",
//                         border: "1px solid black",
//                     }}
//                 />
//                 <div>
//                     <input type="checkbox"></input>
//                     <Link to="">I Accept all Terms and Conditions</Link>
//                 </div>
//                 <Link to='/Login'>Already have an account?</Link>
//             </div>
//             <div style={{
//                 fontFamily: "Arial, sans-serif",
//                 padding: "20px",
//                 maxWidth: "800px",
//                 margin: "auto",
//             }}>
//                 <input type="submit" value="Register" />
//             </div>
//             <ReactPrint
//                 trigger={() => <button>Print</button>}
//                 content={() => ref.current}
//             />
//             <ToastContainer />
//         </>
//     );
// }

// export default Login;







































// import React, { useState } from 'react';
// import axios from 'axios';
// import Cookies from 'js-cookie';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { login } from '../../store/slice/AuthSlice';
// import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import addPhoto from '../../assets/accont.png';

// export default function Register() {
// 	const dispatch = useDispatch();
// 	const navigate = useNavigate();

// 	const [viewImage, setViewImage] = useState(addPhoto);
// 	const [image, setImage] = useState('');
// 	const [name, setName] = useState('');
// 	const [parentsName, setParentsName] = useState('');
// 	const [mobileNumber, setMobileNumber] = useState('');
// 	const [address, setAddress] = useState('');
// 	const [email, setEmail] = useState('');
// 	const [password, setPassword] = useState('');
// 	const [parentsOccupation, setParentsOccupation] = useState('');
// 	const [bloodGroup, setBloodGroup] = useState('');
// 	const [instituteName, setInstituteName] = useState('');
// 	const [courseName, setCourseName] = useState('');
// 	const [subjectName, setSubjectName] = useState('');
// 	const [hobbyName, setHobbyName] = useState('');
// 	const [remarksName, setRemarksName] = useState('');

// 	const convertUserIMG = (e) => {
// 		const file = e.target.files[0];
// 		setImage(file);
// 		const fileReader = new FileReader();
// 		fileReader.readAsDataURL(file);
// 		fileReader.onload = () => {
// 			setViewImage(fileReader.result);
// 		};
// 	};

// 	const handelRegister = async (event) => {
// 		event.preventDefault();
// 		if (!name || !mobileNumber || !address || !email || !password) {
// 			toast.error("Please fill all fields");
// 			return;
// 		}

// 		const formData = new FormData();
// 		formData.append('image', image);
// 		formData.append("name", name);
// 		formData.append("mobileNumber", mobileNumber);
// 		formData.append("address", address);
// 		formData.append("email", email);
// 		formData.append("password", password);

// 		try {
// 			const result = await axios.post(`https://api.dakshineswarshayaklibrary.org/User/register`, formData, {
// 				headers: {
// 					'Content-Type': 'multipart/form-data'
// 				}
// 			});
// 			if (result.data.status === true) {
// 				navigate('/ApproveByOTP');
// 			} else {
// 				toast.error(result.data.message);
// 			}
// 		} catch (error) {
// 			toast.error(error.response?.data?.message || error.message);
// 		}
// 	};

// 	return (
// 		<div className="container">
// 			<div className="title">Registration</div>
// 			<div className="content">
// 				<form onSubmit={handelRegister}>
// 					<div>
// 						<input type="file" onChange={convertUserIMG} />
// 						<img src={viewImage} alt="Uploaded" />
// 					</div>
// 					<div className="user-details">
// 						<div className="input-box">
// 							<span className="details">Full Name</span>
// 							<input
// 								type="text"
// 								placeholder="Enter your name"
// 								value={name}
// 								onChange={(e) => setName(e.target.value)}
// 								required
// 							/>
// 						</div>
// 						<div className="input-box">
// 							<span className="details">Phone Number</span>
// 							<input
// 								type="number"
// 								placeholder="Enter your number"
// 								value={mobileNumber}
// 								onChange={(e) => setMobileNumber(e.target.value)}
// 								required
// 							/>
// 						</div>
// 						<div className="input-box">
// 							<span className="details">Email</span>
// 							<input
// 								type="email"
// 								placeholder="Enter your email"
// 								value={email}
// 								onChange={(e) => setEmail(e.target.value)}
// 								required
// 							/>
// 						</div>
// 						<div className="input-box">
// 							<span className="details">Blood Group</span>
// 							<input
// 								type="text"
// 								placeholder="Enter your Blood Group"
// 								value={bloodGroup}
// 								onChange={(e) => setBloodGroup(e.target.value)}
// 								required
// 							/>
// 						</div>
// 						<div className="input-box">
// 							<span className="details">Password</span>
// 							<input
// 								type="password"
// 								placeholder="Enter your password"
// 								value={password}
// 								onChange={(e) => setPassword(e.target.value)}
// 								required
// 							/>
// 						</div>
// 						<div className="input-box">
// 							<span className="details">Institute name</span>
// 							<input
// 								type="text"
// 								placeholder="Enter your Institute name"
// 								value={instituteName}
// 								onChange={(e) => setInstituteName(e.target.value)}
// 								required
// 							/>
// 						</div>
// 						<div className="input-box">
// 							<span className="details">Address</span>
// 							<input
// 								type="text"
// 								placeholder="Enter your Address"
// 								value={address}
// 								onChange={(e) => setAddress(e.target.value)}
// 								required
// 							/>
// 						</div>
// 						<div className="input-box">
// 							<span className="details">Course name</span>
// 							<input
// 								type="text"
// 								placeholder="Enter your course name"
// 								value={courseName}
// 								onChange={(e) => setCourseName(e.target.value)}
// 								required
// 							/>
// 						</div>
// 						<div className="input-box">
// 							<span className="details">Parents Name</span>
// 							<input
// 								type="text"
// 								placeholder="Enter your Parents Name"
// 								value={parentsName}
// 								onChange={(e) => setParentsName(e.target.value)}
// 								required
// 							/>
// 						</div>
// 						<div className="input-box">
// 							<span className="details">Subject name</span>
// 							<input
// 								type="text"
// 								placeholder="Enter your subject name"
// 								value={subjectName}
// 								onChange={(e) => setSubjectName(e.target.value)}
// 								required
// 							/>
// 						</div>
// 						<div className="input-box">
// 							<span className="details">Parents Occupation</span>
// 							<input
// 								type="text"
// 								placeholder="Enter your Parents Occupation"
// 								value={parentsOccupation}
// 								onChange={(e) => setParentsOccupation(e.target.value)}
// 								required
// 							/>
// 						</div>
// 						<div className="input-box">
// 							<span className="details">Remarks </span>
// 							<input
// 								type="text"
// 								placeholder="Enter your remarks name"
// 								value={remarksName}
// 								onChange={(e) => setRemarksName(e.target.value)}
// 								required
// 							/>
// 						</div>
// 						<div className="input-box">
// 							<span className="details">Hobby </span>
// 							<input
// 								type="text"
// 								placeholder="Enter your Hobby name"
// 								value={hobbyName}
// 								onChange={(e) => setHobbyName(e.target.value)}
// 								required
// 							/>
// 						</div>
// 					</div>
// 					<div className="button">
// 						<input type="submit" value="Register" />
// 					</div>
// 				</form>
// 				<ToastContainer />
// 			</div>
// 		</div>
// 	);
// }
























