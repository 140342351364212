import React, { useState, useEffect } from 'react';
import axios from 'axios';

import addBookImg from '../../assets/addBookImg.png';
import leftArrow from '../../assets/left-arrow-black.png'
import rightArrow from '../../assets/right-arrow-black.png'

export default function AddBook() {
	const [allBooks, setAllBooks] = useState([]);
	const tableHeader = ["Image", "Book ID", "Book Name", "Author"];

	const [bookId, setBookId] = useState();
	const [secondBookId, setSecondBookId] = useState();
	const [viewImage, setViewImage] = useState(addBookImg);
	const [bookIMG, setBookIMG] = useState(null);
	const [name, setName] = useState('');
	const [authorName, setAuthorName] = useState('');
	const [description, setDescription] = useState('');
	const [catagory, setCatagory] = useState('');
	const [quantity, setQuantity] = useState('');
	const [pdf1, setPdf1] = useState(null);
	const [pdf2, setPdf2] = useState(null);


	const [currentPage, setCurrentPage] = useState(1);
	const recordsPage = 5;
	const lastIndex = currentPage * recordsPage;
	const firstIndex = lastIndex - recordsPage;
	const records = allBooks.slice(firstIndex, lastIndex);
	const npage = Math.ceil(allBooks.length / recordsPage);
	// const numbers = [...Array(npage + 1).keys()].slice(1);

	useEffect(() => {
		Handle_Get_All_New_Subject();
	}, []);

	const Handle_Get_All_New_Subject = async () => {

		try {
			const result = await axios.get(`https://api.dakshineswarshayaklibrary.org/User/getBooks`);

			if (result.data.status === true) {
				setAllBooks(result.data.existingBook);
			} else {
				alert(result.data.error);
			}
		} catch (error) {
			alert(error);
		}
	}

	const getRowData = (data) => {
		setBookId(data.bookId)
		setSecondBookId(data.secondBookId)
		setViewImage(`https://api.dakshineswarshayaklibrary.org/uploads/${data.bookIMG}`);
		setName(data.name);
		setAuthorName(data.authorName);
		setDescription(data.description);
		setCatagory(data.catagory);
		setQuantity(data.quantity);
	}

	const convertUserIMG = (e) => {
		const file = e.target.files[0];
		setBookIMG(file);
		const fileReader = new FileReader();
		fileReader.readAsDataURL(file);
		fileReader.onload = () => {
			setViewImage(fileReader.result);
		};
	};

	const handleAddBook = async () => {
		const formData = new FormData();

		formData.append('bookIMG', bookIMG);
		formData.append("name", name);
		formData.append('bookId', bookId);
		formData.append('secondBookId', secondBookId);
		formData.append("authorName", authorName);
		formData.append("description", description);
		formData.append("catagory", catagory);
		formData.append("quantity", quantity);
		formData.append('pdf1', pdf1);
		formData.append('pdf2', pdf2);
		console.log(catagory);
		
		try {
			const result = await axios.post(`https://api.dakshineswarshayaklibrary.org/Admin/addBook`, formData, {
			
				
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			if (result.data.status === true) {		
				alert("Book added successfully!!!");
				Handle_Get_All_New_Subject();
			} else {
				alert(result.data.error);
			}
		} catch (error) {
			console.error(error);
			alert("Failed to add book");
		}
	};

	const handleUpdateBook = async () => {
		const formData = new FormData();

		if (bookIMG !== null) {
			formData.append('bookIMG', bookIMG);
		}
		if (pdf1 !== null) {
			formData.append('pdf1', pdf1);
		}
		if (pdf2 !== null) {
			formData.append('pdf2', pdf2);
		}
		formData.append('bookId', bookId);
		formData.append('secondBookId', secondBookId);
		formData.append("name", name);
		formData.append("authorName", authorName);
		formData.append("description", description);
		formData.append("catagory", catagory);
		formData.append("quantity", quantity);


		try {
			const result = await axios.post(`https://api.dakshineswarshayaklibrary.org/Admin/updateBook`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			if (result.data.status === true) {
				alert("Book updated successfully!!!");
				Handle_Get_All_New_Subject();
			} else {
				alert(result.data.error);
			}
		} catch (error) {
			console.error(error);
			alert("Failed to update book");
		}
	};

	const handleDeleteBook = async () => {
		try {
			const result = await axios.delete(`https://api.dakshineswarshayaklibrary.org/Admin/deleteBook/${bookId}`);
			if (result.data.status === true) {
				alert("Book deleted successfully!!!");
				Handle_Get_All_New_Subject();
			} else {
				alert(result.data.error);
			}
		} catch (error) {
			console.error(error);
			alert("Failed to delete book");
		}
	}



	const prePage = () => {
		if (currentPage !== 1) {
			setCurrentPage(currentPage - 1);
		}
	}

	const nextPage = () => {
		if (currentPage !== npage) {
			setCurrentPage(currentPage + 1);
		}
	}

	const changePage = (n) => {
		setCurrentPage(n);
	}

	// Determine which page numbers to display
	const pageNumbersToDisplay = () => {
		const pages = [];
		const maxPagesToShow = 3;
		let startPage = Math.max(1, currentPage - 1);
		let endPage = Math.min(npage, currentPage + 1);

		if (currentPage - startPage < 1) {
			endPage = Math.min(npage, endPage + (1 - (currentPage - startPage)));
		}

		if (endPage - currentPage < 1) {
			startPage = Math.max(1, startPage - (1 - (endPage - currentPage)));
		}

		for (let i = startPage; i <= endPage; i++) {
			pages.push(i);
		}

		return pages;
	}

	return (
		<div className='h-[calc(100vh-114px)] w-[90%] mx-auto flex justify-center items-center gap-8'>
			<div className='w-[40%] h-[90%] rounded-2xl bg-[#E1D1FF] border border-[#8343FF] flex flex-col items-center'>
				<div className='relative h-[120px] w-[90px]'>
					<input className='absolute opacity-0 w-[90px] h-[120px]' type="file" onChange={convertUserIMG} required />
					<img src={viewImage} alt="Uploaded" className='h-[120px] w-[90px] object-cover mt-5 rounded-lg' />
				</div>
				<h1 className='text-3xl font-bold text-[#8343FF] mt-5'>Add Book Details</h1>
				<div className='mt-5 w-full flex flex-col items-center gap-2'>
					<input type="text" placeholder='Please Enter Book First ID' value={bookId} onChange={(e) => setBookId(e.target.value)} required className='w-[85%] h-[40px] border border-[#8343FF] px-5 outline-none rounded-md' />
					<input type="text" placeholder='Please Enter Book Second ID' value={secondBookId} onChange={(e) => setSecondBookId(e.target.value)} required className='w-[85%] h-[40px] border border-[#8343FF] px-5 outline-none rounded-md' />
					<input type="text" placeholder='Please Enter Book Name' value={name} onChange={(e) => setName(e.target.value)} required className='w-[85%] h-[40px] border border-[#8343FF] px-5 outline-none rounded-md' />
					<input type="text" placeholder='Please Enter Author Name' value={authorName} onChange={(e) => setAuthorName(e.target.value)} required className='w-[85%] h-[40px] border border-[#8343FF] px-5 outline-none rounded-md' />
					<input type="text" placeholder='Please Enter Description' value={description} onChange={(e) => setDescription(e.target.value)} required className='w-[85%] h-[40px] border border-[#8343FF] px-5 outline-none rounded-md' />
					<input type="text" placeholder='Please Enter catagory' value={catagory} onChange={(e) => setCatagory(e.target.value)} required className='w-[85%] h-[40px] border border-[#8343FF] px-5 outline-none rounded-md' />
					<input type="file" onChange={(e) => setPdf1(e.target.files[0])} required className='w-[85%] border border-[#8343FF] px-2 py-1 outline-none rounded-md' />
					<input type="file" onChange={(e) => setPdf2(e.target.files[0])} required className='w-[85%] border border-[#8343FF] px-2 py-1 outline-none rounded-md' />
					<button onClick={handleAddBook} className='bg-[#8343FF] h-[40px] w-[25%] text-xl font-bold text-white mt-2 rounded-md'>Add</button>
					<div className='w-[80%] flex justify-center items-center gap-4'>
						<button onClick={handleUpdateBook} className='w-[35%] bg-[#8343FF] h-[40px] text-white rounded-md'>Update</button>
						<button onClick={handleDeleteBook} className='w-[35%] bg-[#8343FF] h-[40px] text-white rounded-md'>Delete</button>
					</div>
				</div>
			</div>
			<div className='w-[60%] h-[90%]'>
				<div className='h-[90%] w-full overflow-y-auto relative'>
					<table className='w-full border-collapse table-fixed'>
						<thead>
							<tr className='h-[60px]'>
								{tableHeader.map((th, index) => (
									<th key={index} className='bg-[#F4EFFF] text-[#8343FF] sticky top-[-0.1px] z-10 text-2xl font-bold'>{th}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{allBooks ? (
								records.map((data, index) => (
									<tr key={index} onClick={(e) => getRowData(data)} className='h-[135.5px] bg-[#E1D1FF] border-b-[10px] border-[#F4EFFF] hover:text-[#8343FF]'>
										<td className='text-center p-2 text-xl h-[112.6px]'>
											<img src={`https://api.dakshineswarshayaklibrary.org/uploads/${data.bookIMG}`} alt="Uploaded" className='h-full w-[60px] mx-auto object-cover' />
										</td>
										<td className='text-center p-2 text-xl'>{data.bookId}</td>
										<td className='text-center p-2 text-xl'>{data.name}</td>
										<td className='text-center p-2 text-xl'>{data.authorName}</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan={4} className='text-center text-2xl'>No Books Found</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
				<div className='h-[10%] w-full flex justify-center items-end'>
					<ul className='flex justify-center items-center gap-2 h-[80%]'>
						<a href='#' className='page-link h-[50px] w-[50px] flex justify-center items-center text-[#8343FF] text-3xl rounded-full' onClick={prePage}>
							<img src={leftArrow} className='h-[15px] w-[15px]' />
						</a>
						{pageNumbersToDisplay().map((n, i) => (
							<a key={i} href='#' className={`page-link h-[50px] w-[50px] flex justify-center items-center ${currentPage === n ? 'bg-[#E1D1FF] font-bold' : 'text-[#8343FF]'} text-3xl rounded-full`} onClick={() => changePage(n)}>{n}</a>
						))}
						<a href='#' className='page-link h-[50px] w-[50px] flex justify-center items-center text-[#8343FF] text-3xl rounded-full' onClick={nextPage}>
							<img src={rightArrow} className='h-[15px] w-[15px]' />
						</a>
					</ul>
				</div>
			</div>
		</div>

	);
}


