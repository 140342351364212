import React, { useState } from 'react';
import './Navbar.css';
import { useSelector } from 'react-redux';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux'; // Remove useSelector if not used
import { dashboardTrue, dashboardFalse } from '../../store/slice/DashBoardSlice';

export default function Navbar() {

	const whoIsLogin = useSelector((state) => state.auth.user);

	const dispatch = useDispatch();
	const [nav, setNav] = useState(false);
	const [activeLink, setActiveLink] = useState('/');

	const handleLinkClick = (path) => {
		setActiveLink(path);
		dispatch(dashboardFalse());
	};

	const handleDashboardClick = () => {
		setActiveLink(undefined);
		dispatch(dashboardTrue());
	};

	const handleNav = () => {
		setNav(!nav);
	};

	return (
		<div className={`navbar`}>
			<h1 className='logo'>DAKSHINESWAR SHAYAK LIBRARY</h1>

			<ul className='menu'>
				<li className={activeLink ? (activeLink === '/' ? 'navlink active' : 'navlink') : "navlink"}>
					<Link onClick={() => handleLinkClick('/')} to={'/'}>Home</Link>
				</li>
				<li className={activeLink ? (activeLink === '/AllBooks' ? 'navlink active' : 'navlink') : "navlink"}>
					<Link onClick={() => handleLinkClick('/AllBooks')} to={'/AllBooks'}>All Books</Link>
				</li>
				<li className={activeLink ? (activeLink === '/ImageGallery' ? 'navlink active' : 'navlink') : "navlink"}>
					<Link onClick={() => handleLinkClick('/ImageGallery')} to={'/ImageGallery'}>Gallery</Link>
				</li>
				
				{!whoIsLogin || whoIsLogin === null ?
					<li className='Dashboard'>
						<Link onClick={handleDashboardClick} to={'/Login'}>Login</Link>
					</li>
					:
					<>
					<li className={activeLink ? (activeLink === '/NoticeListPage' ? 'navlink active' : 'navlink') : "navlink"}>
					<Link onClick={() => handleLinkClick('/NoticeListPage')} to={'/NoticeListPage'}>Notice</Link>
				</li>
					<li className='Dashboard'>
						<Link onClick={handleDashboardClick} to={'/Dashboard'}>Dashboard</Link>
					</li>
					</>
				}
			</ul>

			<div onClick={handleNav} className='menu-icon'>
				{nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
			</div>

			<ul className={`mobile-menu ${nav ? 'open' : ''}`}>
				<h1 className='mobile-logo'>Library.</h1>
				<li>
					<Link onClick={() => dispatch(dashboardFalse())} to={'/'}>Home</Link>
				</li>
				<li>
					<Link onClick={() => dispatch(dashboardFalse())} to={'/AllBooks'}>All Books</Link>
				</li>
				<li>
					<Link onClick={() => dispatch(dashboardFalse())} to={'/ImageGallery'}>Image Gallery</Link>
				</li>
				<li>
					<Link onClick={() => dispatch(dashboardFalse())} to={'/NoticeListPage'}>Notice</Link>
				</li>
				<li>
					<Link onClick={() => dispatch(dashboardTrue())} to={'/Dashboard'}>Dashboard</Link>
				</li>
			</ul>
		</div>
	);
};
