import React, { useEffect, useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addBookCatagory, deleteBookCatagory } from '../../store/slice/BooksSlice';
import BookCard from '../../components/BookCard/BookCard';

export default function CardSlider({ catagory }) {
 
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [booksToShow, setBooksToShow] = useState([]);
    
    const bookList = useSelector(state => state.bookData.bookList);

    const goSeeAll = (val) => {
        dispatch(deleteBookCatagory());
        dispatch(addBookCatagory(val));
        navigate('/SeeAll');
    }

    const updateBooksToShow = () => {
        const width = window.innerWidth;

        let books;

        if (bookList && width <= 600) {
            books = bookList.slice(-4).reverse();
        } else if (bookList && width >= 600 && width <= 870) {
            books = bookList.slice(-4).reverse();
        } else if (bookList && width >= 872 && width <= 1160) {
            books = bookList.slice(-6).reverse();
        } else if (bookList && width >= 1162 && width <= 1450) {
            books = bookList.slice(-4).reverse();
        } else if (bookList && width >= 1452 && width <= 1500) {
            books = bookList.slice(-5).reverse();
        } else if (bookList && width >= 1502 && width <= 1740) {
            books = bookList.slice(-5).reverse();
        } else if (bookList && width >= 1741 && width <= 1920) {
            books = bookList.slice(-6).reverse();
        } else {
            books = bookList ? bookList.slice(-6).reverse() : [];
        }

        setBooksToShow(books);
    };

    useEffect(() => {
        updateBooksToShow(); // Initial call to set the correct number of books

        window.addEventListener('resize', updateBooksToShow); // Listen for window resize
        return () => window.removeEventListener('resize', updateBooksToShow); // Clean up the event listener on component unmount
    }, [bookList]);





    return (
        <div className="w-[93%] bg-[#E1D1FF] rounded-2xl 
            sm:h-[2200px] 
            md:h-[1160px]
            lg:h-[620px]"
        >
            <div className='w-full h-[60px] flex items-end justify-between px-[50px]'>
                <h2 className='text-[30px] font-bold text-[#8343FF]'>{catagory}</h2>
                <button className='text-[20px] font-bold text-[#8343FF]' onClick={()=>goSeeAll(catagory)}>See All</button>
            </div>
            <div className='w-full flex flex-wrap items-center justify-evenly 
                sm:h-[2100px] 
                md:h-[1100px]
                lg:h-[550px]'
            >
                {
                    booksToShow.length > 0 ?
                        booksToShow
                            .filter(data => data.catagory === catagory)
                            .map(data => (
                                <BookCard key={data.id} data={data} />
                            ))
                        :
                        <></>
                }
            </div>
        </div>
    );
}
