import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

export default function PreviousHistory() {

    const [bookHistory, setBookHistory] = useState([]);

    useEffect(() => {
        handleUpdatePassword();
    }, []);

    const handleUpdatePassword = async () => {
        const token = Cookies.get('auth_token');

        try {
            const result = await axios.get(`https://api.dakshineswarshayaklibrary.org/User/getBookTakingHistory`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });
            if (result.data.status === true) {
                setBookHistory(result.data.bookSubmitDetails);
            } else {
                alert(result.data.error);
            }
        } catch (error) {
            console.error("An error occurred while fetching data:", error);
            alert("An error occurred while fetching data. Please try again.");
        }
    };

    return (
        <table style={{ width: "100%", textAlign: "center" }}>
            <thead style={{ backgroundColor: '#854BF4', height: '80px' }}>
                <tr>
                    <th style={{ color: '#fff', fontSize: '25px' }}>Image</th>
                    <th style={{ color: '#fff', fontSize: '25px' }}>Name</th>
                    <th style={{ color: '#fff', fontSize: '25px' }}>Id</th>
                    <th style={{ color: '#fff', fontSize: '25px' }}>Action</th>
                </tr>
            </thead>
            <tbody style={{ backgroundColor: '#E1D1FF', height: '100px' }}>
                {bookHistory.length > 0 ? (
                    bookHistory.map((item) => (
                        <tr key={item._id}>
                            <td style={{ height: '50px' }}>
                                <img src={`https://api.dakshineswarshayaklibrary.org/uploads/${item.profileIMG}`} alt={item.name} style={{ height: "100%" }} />
                            </td>
                            <td style={{ fontSize: '20px' }}>
                                {item.name}
                            </td>
                            <td>
                                {item._id} 
                            </td>
                            <td>
                                <button>See All</button>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="4" style={{ fontSize: '20px', color: '#333' }}>
                            No books available.
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}












