import React, { useState } from 'react';
import { Routes, Route, Navigate, Link } from 'react-router-dom';
import './Dashboard.css';
import { useSelector } from 'react-redux';
import AddBook from '../Admin/AddBook';
import GiveBook from '../Admin/GiveBook';
import RenueBook from '../Admin/RenueBook';
import ReciveBook from '../Admin/ReciveBook';
import AddGalleryImage from '../Admin/AddGalleryImage';
import AddNotice from '../Admin/AddNotice';
import Profile from '../Profile/Profile';
import BookTaked from '../BookTaked/BookTaked';
import PreviousHistory from '../PreviousHistory/PreviousHistory';
import AccessControl from '../Admin/AccessControl';
import AdminControl from '../Admin/AdminControl';
import MonthlySubscriptionFees from '../Admin/MonthlySubscriptionFees';

import rightIcon from '../../assets/Libraryicon/rightIcon.png';
import leftIcon from '../../assets/Libraryicon/leftIcon.png';
import account from '../../assets/Libraryicon/1.png';
import giveBook from '../../assets/Libraryicon/2.png';
import renuBook from '../../assets/Libraryicon/3.png';
import reciveBook from '../../assets/Libraryicon/4.png';
import addBook from '../../assets/Libraryicon/5.png';
import AGI from '../../assets/Libraryicon/7.png';
import Notice from '../../assets/Libraryicon/8.png';

export default function Dashboard() {

    const whoIsLogin = useSelector((state) => state.auth.user);
    const [isPageResize, setIsPageResize] = useState(true);
    const [activeLink, setActiveLink] = useState('/');


    const handlePageResize = () => {
        isPageResize ? setIsPageResize(false) : setIsPageResize(true)
    }

    const handleLinkClick = (path) => {
        setActiveLink(path);
    };


    return (
        <div className='DashboardPage'>
            {
                whoIsLogin === "Admin" || whoIsLogin === "Super Admin" ?
                    <>
                        <div className={isPageResize ? "DashboardPageLeft maxWidth" : "DashboardPageLeft minWidth"} style={{ gap: isPageResize ? '25px' : '10px' }}>
                            <button className='DashboardPageLeftButton' onClick={handlePageResize}>
                                <img src={isPageResize ? leftIcon : rightIcon}></img>
                            </button>
                            {
                                isPageResize ?
                                    <>
                                        <Link onClick={() => handleLinkClick('/')} className={activeLink === '/' ? 'DashboardPageLeftLink active' : 'DashboardPageLeftLink'} to="/" >
                                            <img src={account}></img>
                                            <h1>Profile</h1>
                                        </Link>
                                        <Link onClick={() => handleLinkClick('/GiveBook')} className={activeLink === '/GiveBook' ? 'DashboardPageLeftLink active' : 'DashboardPageLeftLink'} to="/GiveBook" >
                                            <img src={giveBook}></img>
                                            <h1>Issue Book</h1>
                                        </Link>
                                        <Link onClick={() => handleLinkClick('/RenueBook')} className={activeLink === '/RenueBook' ? 'DashboardPageLeftLink active' : 'DashboardPageLeftLink'} to="/RenueBook" >
                                            <img src={renuBook}></img>
                                            <h1>Renew Book</h1>
                                        </Link>
                                        <Link onClick={() => handleLinkClick('/ReciveBook')} className={activeLink === '/ReciveBook' ? 'DashboardPageLeftLink active' : 'DashboardPageLeftLink'} to="/ReciveBook" >
                                            <img src={reciveBook}></img>
                                            <h1>Recive Book</h1>
                                        </Link>
                                        <Link onClick={() => handleLinkClick('/AddBook')} className={activeLink === '/AddBook' ? 'DashboardPageLeftLink active' : 'DashboardPageLeftLink'} to="/AddBook" >
                                            <img src={addBook}></img>
                                            <h1>Add Book</h1>
                                        </Link>
                                        <Link onClick={() => handleLinkClick('/AddGalleryImage')} className={activeLink === '/AddGalleryImage' ? 'DashboardPageLeftLink active' : 'DashboardPageLeftLink'} to="/AddGalleryImage" >
                                            <img src={AGI}></img>
                                            <h1>Gallery</h1>
                                        </Link>
                                        <Link onClick={() => handleLinkClick('/AddNotice')} className={activeLink === '/AddNotice' ? 'DashboardPageLeftLink active' : 'DashboardPageLeftLink'} to="/AddNotice" >
                                            <img src={Notice}></img>
                                            <h1>Add Notice</h1>
                                        </Link>
                                        {
                                            whoIsLogin === "Super Admin" && (
                                                <Link onClick={() => handleLinkClick('/AccessControl')} className={activeLink === '/AccessControl' ? 'DashboardPageLeftLink active' : 'DashboardPageLeftLink'} to="/AccessControl" >
                                                    <img src={Notice}></img>
                                                    <h1>Controls</h1>
                                                </Link>
                                            )
                                        }
                                        <Link onClick={() => handleLinkClick('/AdminControl')} className={activeLink === '/AdminControl' ? 'DashboardPageLeftLink active' : 'DashboardPageLeftLink'} to="/AdminControl" >
                                            <img src={Notice}></img>
                                            <h1>AdminControl</h1>
                                        </Link>
                                        <Link onClick={() => handleLinkClick('/MonthlySubscriptionFees')} className={activeLink === '/MonthlySubscriptionFees' ? 'DashboardPageLeftLink active' : 'DashboardPageLeftLink'} to="/MonthlySubscriptionFees" >
                                            <img src={Notice}></img>
                                            <h1>Subscription</h1>
                                        </Link>
                                    </>
                                    :
                                    <>
                                        <Link onClick={() => handleLinkClick('/')} className={activeLink === '/' ? 'DashboardPageLeftLink1 active' : 'DashboardPageLeftLink1'} to="/" >
                                            <img src={account}></img>
                                        </Link>
                                        <Link onClick={() => handleLinkClick('/GiveBook')} className={activeLink === '/GiveBook' ? 'DashboardPageLeftLink1 active' : 'DashboardPageLeftLink1'} to="/GiveBook" >
                                            <img src={giveBook}></img>
                                        </Link>
                                        <Link onClick={() => handleLinkClick('/RenueBook')} className={activeLink === '/RenueBook' ? 'DashboardPageLeftLink1 active' : 'DashboardPageLeftLink1'} to="/RenueBook" >
                                            <img src={renuBook}></img>
                                        </Link>
                                        <Link onClick={() => handleLinkClick('/ReciveBook')} className={activeLink === '/ReciveBook' ? 'DashboardPageLeftLink1 active' : 'DashboardPageLeftLink1'} to="/ReciveBook" >
                                            <img src={reciveBook}></img>
                                        </Link>
                                        <Link onClick={() => handleLinkClick('/AddBook')} className={activeLink === '/AddBook' ? 'DashboardPageLeftLink1 active' : 'DashboardPageLeftLink1'} to="/AddBook" >
                                            <img src={addBook}></img>
                                        </Link>
                                        <Link onClick={() => handleLinkClick('/AddGalleryImage')} className={activeLink === '/AddGalleryImage' ? 'DashboardPageLeftLink1 active' : 'DashboardPageLeftLink1'} to="/AddGalleryImage" >
                                            <img src={AGI}></img>
                                        </Link>
                                        <Link onClick={() => handleLinkClick('/AddNotice')} className={activeLink === '/AddNotice' ? 'DashboardPageLeftLink1 active' : 'DashboardPageLeftLink1'} to="/AddNotice" >
                                            <img src={Notice}></img>
                                        </Link>
                                        {
                                            whoIsLogin === "Super Admin" && (
                                                <Link onClick={() => handleLinkClick('/AccessControl')} className={activeLink === '/AccessControl' ? 'DashboardPageLeftLink1 active' : 'DashboardPageLeftLink1'} to="/AccessControl" >
                                                    <img src={Notice}></img>
                                                </Link>
                                            )
                                        }
                                        <Link onClick={() => handleLinkClick('/AdminControl')} className={activeLink === '/AdminControl' ? 'DashboardPageLeftLink1 active' : 'DashboardPageLeftLink1'} to="/AdminControl" >
                                            <img src={Notice}></img>
                                        </Link>
                                        <Link onClick={() => handleLinkClick('/MonthlySubscriptionFees')} className={activeLink === '/MonthlySubscriptionFees' ? 'DashboardPageLeftLink1 active' : 'DashboardPageLeftLink1'} to="/MonthlySubscriptionFees" >
                                            <img src={Notice}></img>
                                        </Link>
                                    </>
                            }

                        </div>
                        <div className={isPageResize ? "DashboardPageRight minWidth" : "DashboardPageRight maxWidth"}>
                            <Routes>
                                <Route path="/" element={<Profile />} />
                                <Route path="*" element={<Navigate to="/" />} />
                                <Route path="/AddBook" element={<AddBook />} />
                                <Route path="/GiveBook" element={<GiveBook />} />
                                <Route path="/RenueBook" element={<RenueBook />} />
                                <Route path="/ReciveBook" element={<ReciveBook />} />
                                <Route path="/AddGalleryImage" element={<AddGalleryImage />} />
                                <Route path="/AddNotice" element={<AddNotice />} />
                                <Route path="/AccessControl" element={<AccessControl />} />
                                <Route path="/AdminControl" element={<AdminControl />} />
                                <Route path="/MonthlySubscriptionFees" element={<MonthlySubscriptionFees />} />
                            </Routes>
                        </div>
                    </>
                    :
                    <>
                        <div className={isPageResize ? "DashboardPageLeft maxWidth" : "DashboardPageLeft minWidth"} style={{ gap: isPageResize ? '25px' : '10px' }}>
                            <button className='DashboardPageLeftButton' onClick={handlePageResize}>
                                <img src={isPageResize ? leftIcon : rightIcon}></img>
                            </button>
                            {
                                isPageResize ?
                                    <>
                                        <Link onClick={() => handleLinkClick('/')} className={activeLink === '/' ? 'DashboardPageLeftLink active' : 'DashboardPageLeftLink'} to="/" >
                                            <img src={account}></img>
                                            <h1>Profile</h1>
                                        </Link>
                                        <Link onClick={() => handleLinkClick('/BookTaked')} className={activeLink === '/BookTaked' ? 'DashboardPageLeftLink active' : 'DashboardPageLeftLink'} to="/BookTaked" >
                                            <img src={giveBook}></img>
                                            <h1>Book Taked</h1>
                                        </Link>
                                        <Link onClick={() => handleLinkClick('/PreviousHistory')} className={activeLink === '/PreviousHistory' ? 'DashboardPageLeftLink active' : 'DashboardPageLeftLink'} to="/PreviousHistory" >
                                            <img src={renuBook}></img>
                                            <h1>Book History</h1>
                                        </Link>
                                    </>
                                    :
                                    <>
                                        <Link onClick={() => handleLinkClick('/')} className={activeLink === '/' ? 'DashboardPageLeftLink1 active' : 'DashboardPageLeftLink1'} to="/" >
                                            <img src={account}></img>
                                        </Link>
                                        <Link onClick={() => handleLinkClick('/BookTaked')} className={activeLink === '/BookTaked' ? 'DashboardPageLeftLink1 active' : 'DashboardPageLeftLink1'} to="/BookTaked" >
                                            <img src={giveBook}></img>
                                        </Link>
                                        <Link onClick={() => handleLinkClick('/PreviousHistory')} className={activeLink === '/PreviousHistory' ? 'DashboardPageLeftLink1 active' : 'DashboardPageLeftLink1'} to="/PreviousHistory" >
                                            <img src={renuBook}></img>
                                        </Link>
                                    </>
                            }

                        </div>
                        <div className={isPageResize ? "DashboardPageRight minWidth" : "DashboardPageRight maxWidth"}>
                            <Routes>
                                <Route path="/" element={<Profile />} />
                                <Route path="*" element={<Navigate to="/" />} />
                                <Route path="/BookTaked" element={<BookTaked />} />
                                <Route path="/PreviousHistory" element={<PreviousHistory />} />
                            </Routes>
                        </div>
                    </>
            }
        </div>
    )
}




