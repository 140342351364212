import React, { useState, useEffect } from "react";
import axios from 'axios';

const AdminControl = () => {
	const [users, setUsers] = useState([]); // State to store users data

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const response = await axios.get('https://api.dakshineswarshayaklibrary.org/Admin/getUsers');
				setUsers(response.data);
			} catch (error) {
				console.error("There was an error fetching the user data!", error);
			}
		};

		fetchUserData();
	}, []);




	const handleAccessChange = (itemId, newAccess) => {
		setUsers((prevUsers) =>
			prevUsers.map((user) =>
				user._id === itemId ? { ...user, access: newAccess } : user
			)
		);
	};



	const handleDelete = async (item) => {
		try {
			const response = await axios.delete(`https://api.dakshineswarshayaklibrary.org/Admin/deleteUser/${item._id}`);
			
			if (response.data.status === true) {
				alert("User deleted successfully!!!");
			} else {
				alert(response.data.error);
			}
	
		} catch (error) {
			console.error("There was an error deleting the user!", error);
		}
	};
	


	const handleSave = async (item) => {
		try {
			const response = await axios.put(`https://api.dakshineswarshayaklibrary.org/Admin/updateAccess/${item._id}`, { access: item.access });
			
			setUsers((prevUsers) =>
				prevUsers.map((user) =>
					user._id === item._id ? { ...user, access: response.data.access } : user
				)
			);
	
			alert(`Saved access: ${response.data.access}`);
		} catch (error) {
			console.error("There was an error updating the user access!", error);
		}
	};

	
	return (
		<table style={{ width: "100%", textAlign: "center" }}>
			<thead style={{ backgroundColor: '#854BF4', height: '80px' }}>
				<tr>
					<th style={{ color: '#fff', fontSize: '25px' }}>Image</th>
					<th style={{ color: '#fff', fontSize: '25px' }}>Name</th>
					<th style={{ color: '#fff', fontSize: '25px' }}>Select Permission</th>
					<th style={{ color: '#fff', fontSize: '25px' }}>Delete User</th>
					<th style={{ color: '#fff', fontSize: '25px' }}>Action</th>
				</tr>
			</thead>
			<tbody style={{ backgroundColor: '#E1D1FF', height: '100px' }}>
				{
					users.map((item) => (
						<tr key={item._id}>
							<td style={{ height: '50px' }}>
								<img src={`https://api.dakshineswarshayaklibrary.org/uploads/${item.profileIMG}`} style={{ height:"100%" }} />
							</td>
							<td style={{ fontSize: '20px' }}>
								{item.name}
							</td>
							<td>
								<select
									value={item.access}
									onChange={(e) => handleAccessChange(item._id, e.target.value)}
								>
									<option value="true">Give Access</option>
									<option value="false">Deny Access</option>
								</select>
							</td>
							<td>
								<button onClick={() => handleDelete(item)} style={{ fontSize: '20px' }}>Delete User</button>
							</td>
							<td>
								<button onClick={() => handleSave(item)} style={{ fontSize: '20px' }}>Save</button>
							</td>
						</tr>
					))
				}
			</tbody>
		</table>
	);
};

export default AdminControl;
