import React from 'react'
import './SeeAll.css'
import { useSelector } from 'react-redux';
import BookCard from '../../components/BookCard/BookCard'

export default function SeeAll() {

    let bookList = useSelector(state => state.bookData.bookList);
    let bookCatagory = useSelector(state => state.bookData.bookCatagory);

    return (
        <div className='pt-24 pb-12 vh-screen w-full flex flex-col items-center justify-center bg-[#E1D1FF]'>
            <div className='mt-5 w-full flex flex-wrap gap-6 items-center justify-center'>
                {bookList ?
                    bookList.map((data) => {
                        if (data.catagory === bookCatagory) {
                            return (
                                <BookCard key={data.id} data={data} />
                            )
                        }
                    })
                    :
                    <></>
                }
            </div>
        </div>
    )
}