import React, { useEffect, useState } from "react";
import axios from "axios";
import "./RenewBook.css";
import bookImg from "../../assets/book.jpg";
import studentImg from '../../assets/student.jpg';


export default function ReciveBook() {

	const [searchBooks, setSearchBooks] = useState("");
	const [bookData, setBookData] = useState(null);

	const [searchStudents, setSearchStudents] = useState("");
	const [studentData, setStudentData] = useState(null);

	const [receiveFine, setReceiveFine] = useState(0);
	const [renewDate, setRenewDate] = useState(new Date().toISOString().split("T")[0]);

	const [check, setCheck] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			handleSearchBooks(searchBooks);
		}, 1000);

		return () => {
			clearTimeout(timer);
		};
	}, [searchBooks]);

	const handleSearchBooks = async (val) => {
		if (val) {
			try {
				const result = await axios.get(`https://api.dakshineswarshayaklibrary.org/Admin/findBookById`, {
					params: { query: val },
				});
				setBookData(result.data.foundBooks);
			} catch (error) {
				console.error(error);
			}
		}
	};


	useEffect(() => {
		const timer = setTimeout(() => {
			handleSearchStudent(searchStudents);
		}, 1000);

		return () => {
			clearTimeout(timer);
		};
	}, [searchStudents]);

	const handleSearchStudent = async (val) => {
		if (val) {
			try {
				const result = await axios.get(`https://api.dakshineswarshayaklibrary.org/Admin/findStudentByEmail`, {
					params: { query: val },
				});
				setStudentData(result.data.foundStudents);
			} catch (error) {
				console.error(error);
			}
		}
	};



	const handleCheck = async () => {
		const formData = {
			bookId: bookData[0].bookId,
			email: studentData[0].email,
		};

		try {
			const result = await axios.post(`https://api.dakshineswarshayaklibrary.org/Admin/checkBookDetails`, formData, {
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (result.data.status) {
				console.log(result.data.data);
				
				setReceiveFine(result.data.data.fine);
				setCheck(true);
			} else {
				alert(result.data.error || "Error occurred while checking book details.");
			}
		} catch (error) {
			console.error(error);
			alert("Wrong Data");
		}
	};






	const handleReciveBook = async () => {
		if (!bookData || !studentData) {
			alert("Please search for a book and a student first.");
			return;
		}

		const formData = {
			bookId: bookData[0].bookId,
			email: studentData[0].email,
			bookSubmitDate: renewDate,
			receiveFine: receiveFine,
		};

		try {
			const result = await axios.post(`https://api.dakshineswarshayaklibrary.org/Admin/receiveBook`, formData, {
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (result.data.status === true) {
				setCheck(false);
				alert("Book renewed successfully!!!");
			} else {
				alert(result.data.error);
			}
		} catch (error) {
			console.error(error);
			alert("Failed to renew book");
		}
	};



	return (
		<div className='RenewBook'>
			<div className='RenewBookLeft'>
				{
					studentData && studentData.length !== 0
						? <img src={`https://api.dakshineswarshayaklibrary.org/uploads/${studentData[0].profileIMG}`} alt="Book Image" className="w-[90px] h-[120px] object-cover mt-8" />
						: <img src={studentImg} alt="Default Image" className="w-[90px] h-[120px] object-cover mt-8" />
				}

				<h1>Student Details</h1>
				<div className='leftCardBottomArea'>
					<h2>Student Name: {studentData && studentData.length !== 0 ? studentData[0].name : " "}</h2>
					<h2>Student Email: {studentData && studentData.length !== 0 ? studentData[0].email : " "}</h2>
				</div>
			</div>
			<div className='RenewBookCenter'>
				{
					bookData && bookData.length !== 0
						? <img src={`https://api.dakshineswarshayaklibrary.org/uploads/${bookData[0].bookIMG}`} alt="Book Image" className="w-[90px] h-[120px] object-cover mt-8" />
						: <img src={bookImg} alt="Default Image" className="w-[90px] h-[120px] object-cover mt-8" />
				}
				<h1>Book Details</h1>
				<div className='leftCardBottomArea'>
					<h2>Book Id: {bookData && bookData.length !== 0 ? bookData[0].bookId : " "}</h2>
					<h2>Book Name: {bookData && bookData.length !== 0 ? bookData[0].name : " "}</h2>
					<h2>Author Name: {bookData && bookData.length !== 0 ? bookData[0].authorName : " "}</h2>
				</div>
			</div>
			<div className='RenewBookRight'>
				<div className='inputCardBottomArea'>
					<div className='inputCardBottomInput'>
						<h2>Student Email: </h2>
						<input type="email" onChange={(e) => setSearchStudents(e.target.value)} ></input>
					</div>
					<div className='inputCardBottomInput'>
						<h2>Book Id: </h2>
						<input type="text" onChange={(e) => setSearchBooks(e.target.value)} ></input>
					</div>
					<div className='inputCardBottomInput'>
						<h2>Amount</h2>
						<input type="text" value={receiveFine} onChange={(e) => setReceiveFine(e.target.value)} ></input>
					</div>
					<div className='inputCardBottomInput'>
						<h2>Return Date: </h2>
						<input type="Date" onChange={(e) => setRenewDate(e.target.value)} ></input>
					</div>
				</div>
				{
					check ? <button onClick={handleReciveBook}>Renew Book</button> : <button onClick={handleCheck}>Handle Check</button>
				}

			</div>
		</div>
	);
}

