import React, { useRef, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { login } from '../../store/slice/AuthSlice';



function Login() {
    const ref = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handelLogin = async () => {
        if (!email || !password) {
            toast.error("Please enter email and password");
            return;
        }

        try {
            const result = await axios.post(`https://api.dakshineswarshayaklibrary.org/User/login`, {
                email,
                password
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (result.data.status === true) {
                setTimeout(() => {
                    Cookies.set('auth_token', result.data.auth_token, { expires: 5 });
                    dispatch(login(result.data.role));
                    navigate('/');
                }, 2000);
            } else {
                toast.error(result.data.message);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || error.message);
        }
    };


    return (
        <div style={{
            fontFamily: "Arial, sans-serif",
            padding: "20px",
            paddingTop:'114px',
            maxWidth: "800px",
            margin: "auto",
        }}>
            <div style={{ marginTop: "50px" }}>
                <label>email:</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{
                        marginLeft: "15px",
                        width: "87%",
                        marginBottom: "10px",
                        border: "1px solid black",
                    }}
                />

                <label>password:</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{
                        marginLeft: "15px",
                        width: "52%",
                        marginBottom: "10px",
                        border: "1px solid black",
                    }}
                />

                <div style={{
                    fontFamily: "Arial, sans-serif",
                    padding: "20px",
                    maxWidth: "800px",
                    margin: "auto",
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center'
                }}>
                  <Link to='/Register'>You have no account? please register now</Link>

                    <button onClick={handelLogin}>Login</button>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default Login;
























