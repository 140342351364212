import React, { useEffect, useState } from 'react';
import './NoticeListPage.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { addNotice, deleteNotice } from '../../store/slice/NoticeSlice';
import { useDispatch } from 'react-redux';

export default function NoticeListPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [allNotice, setAllNotice] = useState([]);

    useEffect(() => {
        handleViewAllNotice();
    }, []);

    const handleViewAllNotice = async () => {
        try {
            const result = await axios.get(`https://api.dakshineswarshayaklibrary.org/User/getNotice`);

            if (result.data.status === true) {
                // Format date here
                const formattedNotices = result.data.allNoticeList.map(notice => ({
                    ...notice,
                    date: formatDate(notice.date)
                }));
                setAllNotice(formattedNotices);
            } else {
                alert(result.data.error);
            }
        } catch (error) {
            console.error(error);
            alert("Failed to fetch notices");
        }
    };

    // Function to format date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    };

    // Function to truncate text
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        }
        return text;
    };

    const viewNotice = (data) => {
        dispatch(deleteNotice());
        dispatch(addNotice(data));
        navigate('/NoticePage');
    };

    return (
        <div className='NoticeListArea'>
            {
                allNotice.length > 0 ?
                    allNotice.map((data, index) => {
                        return (
                            <div className='noticeRow' key={index} onClick={() => viewNotice(data)}>
                                <div className='titleArea'>
                                    <h1>{data.title}</h1>
                                    <h4>{data.date}</h4>
                                </div>
                                <h3>{truncateText(data.description, 400)}</h3>
                            </div>
                        );
                    })
                    :
                    <p>No notices available</p>
            }
        </div>
    );
}
