import React, { useState, useEffect } from "react";
import axios from 'axios';

const AccessControl = () => {
	const [users, setUsers] = useState([]);

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const response = await axios.get('https://api.dakshineswarshayaklibrary.org/Admin/getMembers');
				setUsers(response.data);
			} catch (error) {
				console.error("There was an error fetching the user data!", error);
			}
		};

		fetchUserData();
	}, []);




	const handleRoleChange = (itemId, newRole) => {
		setUsers((prevUsers) =>
			prevUsers.map((user) =>
				user._id === itemId ? { ...user, role: newRole } : user
			)
		);
	};
	const handleSave = async (item) => {
		try {
			const response = await axios.put(`https://api.dakshineswarshayaklibrary.org/Admin/updateRole/${item._id}`, { role: item.role });
			
			setUsers((prevUsers) =>
				prevUsers.map((user) =>
					user._id === item._id ? { ...user, role: response.data.role } : user
				)
			);
	
			alert(`Saved role: ${response.data.role}`);
		} catch (error) {
			console.error("There was an error updating the user role!", error);
		}
	};
	
	return (
		<table style={{ width: "100%", textAlign: "center" }}>
			<thead style={{ backgroundColor: '#854BF4', height: '80px' }}>
				<tr>
					<th style={{ color: '#fff', fontSize: '25px' }}>Image</th>
					<th style={{ color: '#fff', fontSize: '25px' }}>Name</th>
					<th style={{ color: '#fff', fontSize: '25px' }}>Select Permission</th>
					<th style={{ color: '#fff', fontSize: '25px' }}>Action</th>
				</tr>
			</thead>
			<tbody style={{ backgroundColor: '#E1D1FF', height: '100px' }}>
				{
					users.map((item) => (
						<tr key={item._id}>
							<td style={{ height: '50px' }}>
								<img src={`https://api.dakshineswarshayaklibrary.org/uploads/${item.profileIMG}`} style={{ height:"100%" }} />
							</td>
							<td style={{ fontSize: '20px' }}>
								{item.name}
							</td>
							<td>
								<select
									value={item.role}
									onChange={(e) => handleRoleChange(item._id, e.target.value)}
								>
									<option value="Admin">Admin</option>
									<option value="User">User</option>
									<option value="Member">Member</option>
								</select>
							</td>
							<td>
								<button onClick={() => handleSave(item)} style={{ fontSize: '20px' }}>Save</button>
							</td>
						</tr>
					))
				}
			</tbody>
		</table>
	);
};

export default AccessControl;
