import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function AddGalleryImage() {
    const [category, setCategory] = useState('');

    const [imageCategory, setImageCategory] = useState([]);
    const [selectCategory, setSelectCategory] = useState('');
    const [galleryImage, setGalleryImage] = useState([]);

    useEffect(() => {
        getImageCategoryList();
    }, []);

    const getImageCategoryList = async () => {
        try {
            const result = await axios.get(`https://api.dakshineswarshayaklibrary.org/Admin/viewCategory`);

            if (result.data.status === true) {
                setImageCategory(result.data.categoryList);
                setSelectCategory(result.data.categoryList[0]);
            } else {
                alert(result.data.error);
            }
        } catch (error) {
            console.error(error);
            alert("Failed to fetch category list");
        }
    }



    const handleAddGalleryCatagory = async () => {
        const formData = new FormData();
        formData.append("category", category);

        try {
            const result = await axios.post(`https://api.dakshineswarshayaklibrary.org/Admin/addCategory`, formData, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            if (result.data.status === true) {
                alert("Catagory added successfully!!!");
                setCategory('');
                getImageCategoryList();
            } else {
                alert(result.data.error);
            }
        } catch (error) {
            console.error(error);
            alert("Failed to add book");
        }
    };


    const handlePdf2Change = (e) => {
        const files = Array.from(e.target.files);
        setGalleryImage(files);
    };



    const handleAddImage = async () => {
        const formData = new FormData();


        formData.append("selectCategory", selectCategory);
        galleryImage.forEach((file, index) => {
            formData.append("galleryImage", file);
        });

        try {
            const result = await axios.post(`https://api.dakshineswarshayaklibrary.org/Admin/addImagesFromGallery`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (result.data.status === true) {
                alert("Images added successfully!!!");
                setGalleryImage([])
            } else {
                alert(result.data.error);
            }
        } catch (error) {
            console.error(error);
            alert("Failed to add images");
        }
    };


    return (
        <div className='h-[calc(100vh-114px)] w-[90%] mx-auto flex flex-col items-center justify-center gap-5'>
            <div className='w-full h-[30%] flex flex-col items-center justify-center gap-5 border border-[#8343FF] bg-[#E1D1FF] rounded-lg'>
                <h1 className='text-3xl font-bold text-[#8343FF]'>Add Category</h1>
                <div className='w-[60%] flex items-center justify-center gap-5'>
                    <input
                        className='w-[70%] h-[40px] border border-[#8343FF] outline-none px-5 rounded-md'
                        type='text'
                        placeholder='Please enter category'
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        required
                    />
                    <button
                        onClick={handleAddGalleryCatagory}
                        className='h-[40px] w-[100px] text-white font-bold bg-[#8343FF] rounded-md'
                    >
                        Add
                    </button>
                </div>
            </div>

            <div className='w-full h-[30%] flex flex-col items-center justify-center gap-5 border border-[#8343FF] bg-[#E1D1FF] rounded-lg'>
                <h1 className='text-3xl font-bold text-[#8343FF]'>Add Image</h1>
                <div className='w-[60%] flex items-center justify-center gap-5'>
                    {imageCategory.length > 0 ? (
                        <select
                            className='w-[39%] h-[40px] border border-[#8343FF] outline-none px-5 rounded-md'
                            value={selectCategory}
                            onChange={(e) => setSelectCategory(e.target.value)}
                        >
                            {imageCategory.map((category, index) => (
                                <option key={index} value={category}>
                                    {category}
                                </option>
                            ))}
                        </select>
                    ) : (
                        <div className='w-[39%] h-[40px] border border-[#8343FF] bg-white flex items-center justify-center rounded-md'>
                            <p>No categories found</p>
                        </div>
                    )}
                    <label
                        htmlFor="file-upload"
                        className='w-[29%] h-[40px] border border-[#8343FF] bg-white flex items-center justify-center rounded-md cursor-pointer'
                    >
                        {galleryImage.length !== 0 ? (
                            <span>{galleryImage.length} Files Chosen</span>
                        ) : (
                            <span>Choose Files</span>
                        )}
                        <input
                            id="file-upload"
                            type="file"
                            onChange={handlePdf2Change}
                            multiple
                            required
                            hidden
                        />
                    </label>
                    <button
                        className='h-[40px] w-[100px] text-white font-bold bg-[#8343FF] rounded-md'
                        onClick={handleAddImage}
                    >
                        Add
                    </button>
                </div>
            </div>
        </div>

    );
}


