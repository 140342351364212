import React from 'react'
import { useSelector } from 'react-redux';
import './NoticePage.css';

export default function NoticePage() {

    const noticeData = useSelector((state) => state.noticeData.notice);

    return (
        <div className='NoticePage'>
            {
                noticeData ?
                    <div className='noticeArea'>
                        <div className='noticeTitleArea'>
                            <h1>{noticeData.title}</h1>
                            <h4>{noticeData.date}</h4>
                        </div>
                        <p>{noticeData.description}</p>
                    </div>
                    :
                    <></>
            }
        </div>
    )
}

