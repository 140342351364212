import React, { useState } from 'react';
import axios from 'axios';


export default function AddNotice() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const handleAddNotice = async () => {
        const formData = new FormData();

        formData.append("title", title);
        formData.append("description", description);

        try {
            const result = await axios.post(`https://api.dakshineswarshayaklibrary.org/Admin/addNotice`, formData, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            if (result.data.status === true) {
                alert("Notice added successfully!!!");
            } else {
                alert(result.data.error);
            }
        } catch (error) {
            console.error(error);
            alert("Failed to add book");
        }
    };

    return (
        <div className='h-[calc(100vh-114px)] w-[90%] mx-auto flex flex-col justify-center'>
            <label htmlFor="notice-title" className='block mb-1 text-base font-medium'>Add Notice Title</label>
            <input
                type="text"
                id="notice-title"
                name="notice-title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter Notice Title"
                className='w-[50%] p-2 border border-gray-300 rounded-md box-border mb-2'
                required
            />
            <label htmlFor="notice-description" className='block mb-1 text-base font-medium'>Add Notice Description</label>
            <textarea
                id="notice-description"
                name="notice-description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter Notice Description"
                rows="4"
                className='w-full h-[70%] p-2 border border-gray-300 rounded-md box-border mb-2'
                required
            ></textarea>
            <button
                onClick={handleAddNotice}
                className='bg-[#8343FF] text-white py-2 px-5 text-xl font-bold rounded-md cursor-pointer'
            >
                ADD
            </button>
        </div>

    );
}
