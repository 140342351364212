import React, { useEffect, useState } from 'react';
import axios from 'axios';
import bookImg from '../../assets/book.jpg';
import studentImg from '../../assets/student.jpg';

export default function GiveBook() {
    const [searchBooks, setSearchBooks] = useState('');
    const [bookData, setBookData] = useState();

    const [searchStudents, setSearchStudents] = useState('');
    const [studentData, setStudentData] = useState();

    const [bookAllowDate, setBookAllowDate] = useState(new Date().toISOString().split('T')[0]);
    const [renewDate, setRenewDate] = useState(new Date().toISOString().split('T')[0]);

    useEffect(() => {
        const timer = setTimeout(() => {
            handleSearchBooks(searchBooks);
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [searchBooks]);

    const handleSearchBooks = async (val) => {
        if (val) {
            try {
                const result = await axios.get(`https://api.dakshineswarshayaklibrary.org/Admin/findBookById`, {
                    params: { query: val }
                });
                setBookData(result.data.foundBooks);
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            handleSearchStudent(searchStudents);
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [searchStudents]);

    const handleSearchStudent = async (val) => {
        if (val) {
            try {
                const result = await axios.get(`https://api.dakshineswarshayaklibrary.org/Admin/findStudentByEmail`, {
                    params: { query: val }
                });
                setStudentData(result.data.foundStudents);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleGiveBook = async () => {
        if (!bookData || !studentData) {
            alert("Please search for a book and a student first.");
            return;
        }

        const formData = new FormData();

        formData.append('bookId', bookData[0].bookId);
        formData.append("email", studentData[0].email);
        formData.append("bookAllowDate", bookAllowDate);
        formData.append("renewDate", renewDate);

        try {
            const result = await axios.post(`https://api.dakshineswarshayaklibrary.org/Admin/giveBook`, formData, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            if (result.data.status === true) {
                alert("Book Giving successfully!!!");
            } else {
                alert(result.data.error);
            }
        } catch (error) {
            console.error(error);
            alert("Failed to Giving book");
        }
    };

    return (
        <div className="flex justify-center items-center gap-8 h-[calc(100vh-114px)] w-[90%] mx-auto">
            <div className="w-[40%] h-[90%] rounded-xl bg-[#E1D1FF] border border-[#8343FF] flex flex-col justify-start items-center">
                {
                    bookData && bookData.length !== 0
                        ? <img src={`https://api.dakshineswarshayaklibrary.org/uploads/${bookData[0].bookIMG}`} alt="Book Image" className="w-[90px] h-[120px] object-cover mt-8" />
                        : <img src={bookImg} alt="Default Image" className="w-[90px] h-[120px] object-cover mt-8" />
                }
                <h1 className="text-2xl font-bold text-[#8343FF] mt-5">Book Details</h1>
                <div className="w-[90%] mt-[100px]">
                    <h2 className="text-lg text-[#8343FF]">Book Id: {bookData && bookData.length !== 0 ? bookData[0].bookId : " "}</h2>
                    <h2 className="text-lg text-[#8343FF]">Book Name: {bookData && bookData.length !== 0 ? bookData[0].name : " "}</h2>
                    <h2 className="text-lg text-[#8343FF]">Author Name: {bookData && bookData.length !== 0 ? bookData[0].authorName : " "}</h2>
                </div>
            </div>
            <div className="w-[60%] h-[90%] flex flex-col justify-center items-center gap-8">
                <div className="h-[50%] w-full rounded-xl bg-[#E1D1FF] border border-[#8343FF] flex flex-col justify-start items-center">
                    {
                        studentData && studentData.length !== 0
                            ? <img src={`https://api.dakshineswarshayaklibrary.org/uploads/${studentData[0].profileIMG}`} alt="Student Image" className="w-[100px] h-[100px] rounded-full object-cover mt-8" />
                            : <img src={studentImg} alt="Default Image" className="w-[100px] h-[100px] rounded-full object-cover mt-8" />
                    }
                    <h1 className="text-2xl font-bold text-[#8343FF] mt-5">Student Details</h1>
                    <div className="w-[90%] mt-12">
                        <h2 className="text-lg text-[#8343FF]">Student Name: {studentData && studentData.length !== 0 ? studentData[0].name : " "}</h2>
                        <h2 className="text-lg text-[#8343FF]">Student Email: {studentData && studentData.length !== 0 ? studentData[0].email : " "}</h2>
                    </div>
                </div>
                <div className="h-[50%] w-full rounded-xl bg-[#E1D1FF] border border-[#8343FF] flex flex-col justify-start items-center">
                    <h1 className="text-2xl font-bold text-[#8343FF] mt-5">Issue Book</h1>
                    <div className="mt-8 w-full flex flex-col justify-center items-center gap-4">
                        <div className="w-[90%] flex justify-start items-center">
                            <h2 className="w-[30%] text-lg text-[#8343FF]">Book Id:</h2>
                            <input type="text" onChange={(e) => setSearchBooks(e.target.value)} className="w-[70%] h-[40px] border border-[#8343FF] pl-5 outline-none rounded-md" />
                        </div>
                        <div className="w-[90%] flex justify-start items-center">
                            <h2 className="w-[30%] text-lg text-[#8343FF]">Student Email:</h2>
                            <input type="email" onChange={(e) => setSearchStudents(e.target.value)} className="w-[70%] h-[40px] border border-[#8343FF] pl-5 outline-none rounded-md" />
                        </div>
                        <div className="w-[90%] flex justify-start items-center">
                            <h2 className="w-[30%] text-lg text-[#8343FF]">Issue Date:</h2>
                            <input type="date" required value={bookAllowDate} onChange={(e) => setBookAllowDate(e.target.value)} className="w-[70%] h-[40px] border border-[#8343FF] pl-5 outline-none rounded-md" />
                        </div>
                        <div className="w-[90%] flex justify-start items-center">
                            <h2 className="w-[30%] text-lg text-[#8343FF]">Return Date:</h2>
                            <input type="date" required value={renewDate} onChange={(e) => setRenewDate(e.target.value)} className="w-[70%] h-[40px] border border-[#8343FF] pl-5 outline-none rounded-md" />
                        </div>
                        <button onClick={handleGiveBook} className="bg-[#8343FF] h-[40px] w-[50%] text-lg font-bold text-white mt-2 rounded-md">Issue Book</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

