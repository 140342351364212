import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BookCard from '../../components/BookCard/BookCard';

export default function AllBooks() {
    const [searchBooks, setSearchBooks] = useState('');
    const [foundBooks, setFoundBooks] = useState([]);

    const handleSearch = async (val) => {
        try {
            const result = await axios.get(`https://api.dakshineswarshayaklibrary.org/User/searchBooks`, {
                params: { query: val }
            });
            setFoundBooks(result.data.foundBooks);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            handleSearch(searchBooks);
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [searchBooks]);

    return (
        <div className='pt-24 pb-12 vh-screen w-full flex flex-col items-center justify-center bg-[#E1D1FF]'>
            <div className='w-11/12 max-w-screen-lg flex items-center justify-center h-[150px]'>
                <input 
                    type='search' 
                    placeholder='Search here' 
                    onChange={(e) => setSearchBooks(e.target.value)} 
                    className='h-[45%] sm:w-[90%] md:w-[80%] lg:w-[70%] w-[90%] px-6 text-xl border rounded-lg border-[#8343FF] outline-none '
                />
            </div>
            <div className='mt-5 w-full flex flex-wrap gap-6 items-center justify-center'>
                {foundBooks && foundBooks.map((data) => (
                    <BookCard key={data.id} data={data} />
                ))}
            </div>
        </div>
    );
}
